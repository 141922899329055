@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes spin {
  to { transform: rotate(360deg); }
}

.spinner-border {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
}
